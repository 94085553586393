<body class="afterlogin ">
    <!-- bg-image -->
    <div class="login-wrapper background">
        <div class="container-common">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <form class="login_box_outer" [formGroup]="forgotPassword">
                        <div class="login-box max-WT-520" style=" opacity: 0.8; background: rgba(0,0,1,0.8);">
                            <div class="login-right-block">
                                <div class="text-center mb-3">
                                    <img src="assets\reh-img\reh-logo.png" alt="crypto-logo" class="data-img">
                                </div>
                                <div class="login-heading">
                                    <h4>FORGOT PASSWORD?</h4>
                                </div>
                                <div class="login-box-body">
                                    <p class="common-paragrph text-center">Please enter a registered email address so
                                        that we can send you reset instruction</p>
                                    <div class="form-group"> <input type="email" formControlName="email"
                                            class="form-control" placeholder="Email Address" required /> <span
                                            class="error">
                                            <p *ngIf="forgotPassword.get('email').hasError('pattern') && forgotPassword.get('email').dirty"
                                                class="error" padding>*Please enter valid email</p>
                                        </span> </div>
                                    <div class="form-group text-center"> <button type="submit"
                                            class="btn btn-login btn-small width100 font-100 mr-2" (click)="onForgot()"
                                            style="background-color: #2d3498"
                                            [disabled]="forgotPassword.invalid">SUBMIT</button> <button type="submit"
                                            class="btn btn-login btn-small width100 font-100"
                                            [routerLink]="['/login']">BACK</button> </div>
                                </div> <a (click)="onResend()" class="text-center mt20 reset-link">Resend Link ?</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</body>